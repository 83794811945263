import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import searchData, { SearchData } from '@/shared/model/smallPayloadModels/searchData';
import { ROUTES } from '@/router/routesEnum';
import zrStat, { StatusIndices as ZrStatus, zrStatusesList } from '@/shared/model/smallPayloadModels/zrStatus';
import wochenabschluesseSearchData, {
  WochenabschluesseSearchData,
} from '@/shared/model/smallPayloadModels/wochenabschluesseSearchData';
import DateField from '@/components/_common/date-field/DateField.vue';
import { CONST } from '@/shared/utils/Constants';
import { UserData } from '@/shared/model/userData';
import wochenabschluesse, {
  Wochenabschluesse,
  ListlogEndWeek,
  HistoriesFileOfEndWeek,
} from '@/shared/model/wochenabschluesse';
import StatusDialog from './status-dialog/status-dialog.vue';
import { DefaultBackendHelper } from '@/shared/utils/backendHelper';
import GeneralUtils from '@/shared/utils/generalUtils';
import DownloadUtils from '@/shared/utils/DownloadUtils';

const logger = new Logger('login');
const zrDocumentModule = namespace('zrDocument');
const authModule = namespace('auth');
const userDataModule = namespace('userData');
const wochenabschluesseData = namespace('wochenabschluesse');

@Component({
  components: { D4yTable, DateField },
})
export default class ZRDocuments extends Vue {
  @authModule.Action('canSeeAll')
  private actionCanSeeAll!: any;
  @authModule.Getter('isUserInAzureAdminGroup')
  private isUserInAzureAdminGroupGetter!: any;
  @authModule.Getter('isUserSuperAdmin')
  private isUserSuperAdmin!: any;
  @authModule.Getter('isUserGsUser')
  private isUserGsUser!: any;
  @authModule.Getter('isUserOnlyGs')
  private isUserOnlyGs!: any;
  @authModule.Getter('zrNummer')
  private zrNummer!: any;
  @authModule.Getter('zrNummerWithRoles')
  private zrNummerWithRoles!: any;
  @authModule.Getter('isInitialRedirect')
  private isInitialRedirectGetter!: any;

  @authModule.Getter('isUserHasRightsForEndOfWeek')
  private isUserHasRightsForEndOfWeek!: any;

  @zrDocumentModule.Action('getAzureFile')
  private actionGetAzureFile!: any;

  @userDataModule.Action('updateUserData')
  private actionUpdateUserData!: any;
  @userDataModule.Action('getUserData')
  private actionGetUserData!: any;

  @wochenabschluesseData.Getter('getWochenabschluesseSearchData')
  private getWochenabschluesseSearchData!: any;
  @wochenabschluesseData.Action('getWochenabschluesse')
  private actiongetWochenabschluesseData!: any;
  @wochenabschluesseData.Getter('getWochenabschluesse')
  private getWochenabschluesse!: any;

  @wochenabschluesseData.Getter('getWochenabschluesseSearchParams')
  private zrWochenabschluesseSearchParams!: any;
  @wochenabschluesseData.Getter('getWochenabschluesseIsLoading')
  private wochenabschluesseIsLoading!: boolean;
  @wochenabschluesseData.Action('updateWochenabschluesseSearchData')
  private actionUpdateWochenabschluesseSearchData!: any;
  @wochenabschluesseData.Action('getWochenabschluesseFacetSearch')
  private actionGetWochenabschluesseFacetSearch!: any;
  @wochenabschluesseData.Action('getWochenabschluesseFileDownloadLink')
  private actionGetWochenabschluesseFileDownloadLink!: any;

  @wochenabschluesseData.Action('createWochenabschluesseLogRecords')
  private actionCreateWochenabschluesseLogRecords!: any;

  @wochenabschluesseData.Action('sendWochenabschluesseRabbitMqMess')
  private actionSendWochenabschluesseRabbitMqMess!: any;

  @wochenabschluesseData.Getter('isWochenabschluesseSearchDataEmpty')
  private isWochenabschluesseSearchDataEmpty!: any;

  @wochenabschluesseData.Getter('getIsWochenabschluesseFileDownload')
  private isWochenabschluesseFileDownload!: boolean;
  @wochenabschluesseData.Getter('isSearchDataEmpty')
  private isSearchDataEmpty!: any;

  @wochenabschluesseData.Action('getListlogEndWeek')
  private actionGetListlogEndWeek!: any;

  @wochenabschluesseData.Action('getHistoryFileOfEndWeek')
  private actionHistoryFileOfEndWeek!: (documentId: string) => Promise<any>;

  private previousTimeoutId: any = 0;
  private searchPayload: any = {};
  private tableView = 0;
  listlogEndWeek: ListlogEndWeek[] = [];
  historyFileOfEndWeekList: HistoriesFileOfEndWeek[] = [];

  @Watch('getWochenabschluesseSearchData', { deep: true })
  public async onOptionsFilterChanged(newVal: WochenabschluesseSearchData, oldVal: WochenabschluesseSearchData) {
    const areObjectsEqual = GeneralUtils.areObjectsEqual(newVal, oldVal, ['isUserInAzureAdminGroup']); // decided to ignore 2 props than include logic for them in `search-form` component

    if (areObjectsEqual || this.isQueryBeforeCreatedDataLoad) {
      return;
    }
    let payload: any = {
      wochenabschluesseSearchData: {
        zrNummer: newVal.zrNummer,
        receiverNumbers: newVal.receiverNumbers,
        titles: newVal.titles,
        isShowAllData: newVal.isShowAllData,
        datum: newVal.datum,
        viewTable: this.tableView,
        searchFacetFields: this.zrWochenabschluesseSearchParams.andClauseFieldsIds,
      },
    };
    this.searchPayload = payload.wochenabschluesseSearchData;

    localStorage.setItem('wochenabschluesseSearchData', JSON.stringify(payload.wochenabschluesseSearchData));

    if (!localStorage.getItem('wochenabschluesse-filter')) {
      this.zrWochenabschluesseSearchParams.filter = ''; // clear Odata filter, cleared here to avoid extra backend query from `@update:options="selectionUpdateOptions"`
    }

    // set correct payload for `download PDF/excel` request
    this.getWochenabschluesseSearchData.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    this.getWochenabschluesseSearchData.zrNummer = this.getZrNummerDependOnRole();

    // ignore request to backend until rights checked and tabled showed
    // showed use after `localStorage.setItem('searchData'` to work properly
    if (!this.isUserLoggedInAndHasRights) return;

    payload.wochenabschluesseSearchData.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    if (this.isUserGsUser) {
      payload.wochenabschluesseSearchData.zrNummer = this.getZrNummerDependOnRole(); // this.zrNummer;
    }

    this.zrWochenabschluesseSearchParams.dataOption.page = 1;
    localStorage.setItem('wochenabschluesseSignalR', JSON.stringify(payload)); // (GSP-282) save payload for correct response from SignalR (otherwise return ALL wochenabschluesse)
    await this.actiongetWochenabschluesseData(payload)
      .then((result: any) => {})
      .catch((err: any) => {
        logger.error(err);
      });

    await this.updateWochenabschluesseSearchDataBasedOnLastUpdate();
  }

  getZrNummerDependOnRole() {
    let result = this.isUserOnlyGs ? this.zrNummerWithRoles.endOfWeekZrNummer + '' || '-' : this.zrNummer + '';
    return result;
  }
  get isUserLoggedInAndHasRights() {
    return (
      (this.isUserInAzureAdminGroupGetter || (this.isUserGsUser && !!this.zrNummer)) && this.isUserHasRightsForEndOfWeek
    );
  }

  // avoid extra query from watcher on initial page load
  get endOfWeekLoaded() {
    return this.wochenabschluesseIsLoading !== undefined;
  }
  get isQueryBeforeCreatedDataLoad() {
    return this.wochenabschluesseIsLoading === undefined;
  }

  get tableText() {
    return `${this.getWochenabschluesse.total} Artikel`;
  }

  get initialRedirect() {
    return this.isInitialRedirectGetter;
  }

  get isAllFiltersEmpty() {
    return (
      this.isWochenabschluesseSearchDataEmpty && !this.zrWochenabschluesseSearchParams.filter && !this.chipDataString
    );
  }

  get isEmptyMitglied() {
    return (
      this.getWochenabschluesseSearchData?.receiverNumbers?.length == 0 ||
      !this.getWochenabschluesseSearchData?.receiverNumbers
    );
  }

  get isEmptyMitgliedListForGsUser() {
    return this.isUserOnlyGs && this.isEmptyMitglied;
  }

  // (GSP-103) show user ZrNummer for `GsUser` if nothing selected in combobox
  get mitgliedList() {
    return this.isEmptyMitgliedListForGsUser
      ? this.getZrNummerDependOnRole()
      : this.getWochenabschluesseSearchData?.receiverNumbers?.join(', ');
  }

  async created() {
    await this.actionCanSeeAll().catch((err: any) => {
      logger.error(err);
    });

    if (this.initialRedirect) return;

    localStorage.removeItem('wochenabschluesseSearchDataLastUpdate'); // reset (make null) localsStorage update time on reload
    await this.actionGetUserData(CONST.emptyGuid).then((result: UserData) => {
      if (result?.wochenabschluesseSearchData) {
        logger.log('actionGetUserData result:>> ', result);

        const wochenabschluesseSearchData = JSON.parse(result.wochenabschluesseSearchData);
        this.searchPayload = wochenabschluesseSearchData.wochenabschluesseSearchData || {}; // use empty object if `searchData` undefined;
        this.actionUpdateWochenabschluesseSearchData(wochenabschluesseSearchData.wochenabschluesseSearchData);
        localStorage.setItem('wochenabschluesse-filter', wochenabschluesseSearchData.odataFilter);
        if (wochenabschluesseSearchData.chipDataString) {
          localStorage.setItem('wochenabschluesse-chipdata', wochenabschluesseSearchData.chipDataString);
          this.parseLocalStorageChips(wochenabschluesseSearchData.chipDataString);
        }
      }
    });

    if (this.isUserLoggedInAndHasRights) {
      await this.getWochenabschluesseData();
      let zrNummer = this.getZrNummerDependOnRole();
      await this.actionGetListlogEndWeek({
        zrNummer: zrNummer,
        isUserInAzureAdminGroupGetter: this.isUserInAzureAdminGroupGetter,
      })
        .then((result: any) => {
          this.listlogEndWeek = result;
        })
        .catch((err: any) => {
          logger.error(err);
        });
    }
  }

  parseLocalStorageChips(chipDataStr: any) {
    const values = chipDataStr.split(',');
    this.zrWochenabschluesseSearchParams.andClauseFieldsIds = []; // (GSP-095) avoid duplication in `andClauseFieldsIds` (when return from any other component (any form, invoices list))
    values.forEach((element: string) => {
      const field = element.split(':')[0];
      const word = element.split(':')[1];
      const fieldNameTranslated = this.$i18n.tc(`facet_search.${field}`);
      this.chipData.push({
        chipText: `${fieldNameTranslated}: ${word}`,
        chipField: field,
        searchWord: word,
      });

      this.zrWochenabschluesseSearchParams.andClauseFieldsIds.push({
        chipField: field,
        searchWord: word,
      });
    });
  }

  private isMounted = false; // avoid initial extra request in `selectionUpdateOptions`
  async mounted() {
    this.isMounted = true;
  }

  get items() {
    if (this.tableView == 0) {
      return this.getWochenabschluesse.items.map((x: any, i: number) => {
        x.id = i;
        return x;
      });
    } else {
      return this.getWochenabschluesse.items;
    }
  }

  get getterListlogEndWeek() {
    return this.listlogEndWeek;
  }

  private async getWochenabschluesseData() {
    // don't take getter value in this case
    //this.searchPayload.isShowAllData = this.getWochenabschluesseSearchData.isShowAllData;
    this.searchPayload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    if (this.isUserGsUser) {
      this.searchPayload.zrNummer = this.getZrNummerDependOnRole();
    }
    this.searchPayload.viewTable = this.tableView;
    this.searchPayload.searchFacetFields = this.zrWochenabschluesseSearchParams.andClauseFieldsIds;
    const payload = {
      searchParams: this.zrWochenabschluesseSearchParams,
      wochenabschluesseSearchData: this.searchPayload,
    }; // sent search request from localStorage on reload
    await this.actiongetWochenabschluesseData(payload)
      .then((result: any) => {
        logger.log('result :>> ', result);
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private async getWochenabschluesseDataWithParams() {
    this.searchPayload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    this.searchPayload.isUserInOnlyGs = this.isUserOnlyGs;
    if (this.isUserGsUser) {
      this.searchPayload.zrNummer = this.getZrNummerDependOnRole();
    }
    this.searchPayload.viewTable = this.tableView;
    this.searchPayload.searchFacetFields = this.zrWochenabschluesseSearchParams.andClauseFieldsIds;
    await this.actiongetWochenabschluesseData({
      searchParams: this.zrWochenabschluesseSearchParams,
      wochenabschluesseSearchData: this.searchPayload,
    }).then(async () => {
      await this.updateWochenabschluesseSearchDataBasedOnLastUpdate();
    });
  }

  private async updateWochenabschluesseSearchDataBasedOnLastUpdate() {
    let timeFromLastWochenabschluesseSearchDataUpdateInSeconds =
    this.geTimeFromLastWochenabschluesseSearchDataUpdateInSeconds();
    // avoid extra queries to database , but save immediately when clear all filters
    if (timeFromLastWochenabschluesseSearchDataUpdateInSeconds > 1.5) {
      // || this.isInvoiceSearchDataEmpty) {
      await this.updateWochenabschluesseSearchDataInDatabase();
    } else {
      clearTimeout(this.previousTimeoutId);
      this.previousTimeoutId = setTimeout(() => this.updateWochenabschluesseSearchDataInDatabase(), 2000);
    }
  }

  async updateWochenabschluesseSearchDataInDatabase() {
    const userDataPayload = {
      wochenabschluesseSearchData: {
        wochenabschluesseSearchData: this.searchPayload,
        odataFilter: localStorage.getItem('wochenabschluesse-filter') || '',
        chipDataString: this.chipDataString || '',
      },
      isWochenabschluesseSearch: true,
    };

    await this.actionUpdateUserData(userDataPayload)
      .then((result: any) => {})
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private geTimeFromLastWochenabschluesseSearchDataUpdateInSeconds() {
    let timeFromLastWochenabschluesseSearchDataUpdateInSeconds = 0;
    if (!localStorage.getItem('wochenabschluesseSearchDataLastUpdate')) {
      localStorage.setItem('wochenabschluesseSearchDataLastUpdate', new Date().getTime() + '');
    } else {
      const currentTime = new Date().getTime();
      timeFromLastWochenabschluesseSearchDataUpdateInSeconds =
        (currentTime - +localStorage.getItem('wochenabschluesseSearchDataLastUpdate')!) / 1000;
      localStorage.setItem('wochenabschluesseSearchDataLastUpdate', new Date().getTime() + '');
    }

    return timeFromLastWochenabschluesseSearchDataUpdateInSeconds;
  }

  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      align?: string;
      class?: string | string[];
      sortable?: boolean;
      width?: string;
    }[] = [
      {
        text: this.$t('anzahl'),
        value: 'expand-action',
        sortable: false,
        width: '10px',
        align: 'center',
      },
      { text: this.$t('auflistungsdatum_date'), value: 'end_of_week_date', width: '65px', align: 'left' },
      { text: this.$t('mitglied'), value: 'full_name' },
    ];
    return headers;
  }

  get altHeaders() {
    let headers: {
      text: string | any;
      value: string;
      align?: string;
      class?: string | string[];
      sortable?: boolean;
      width?: string;
    }[] = [
      {
        text: '', //this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
      },
      { text: this.$t('auflistungsdatum_date'), value: 'end_of_week_date', width: '65px', align: 'left' },
      { text: this.$t('mitglied'), value: 'full_name' },
      { text: this.$t('titleColumn'), value: 'file_title' },
      { text: this.$t('dateiname'), value: 'file_name' },
      {
        text: this.$t('actions'),
        value: 'actionsWA',
        sortable: false,
        width: '1%',
      },
    ];
    return headers;
  }

  private openZrDocument(item: any) {
    logger.log(
      '------------------------------------------------------------------------------------------------------openZrDocument item :>> ',
      item
    );

    this.$router.push({
      name: ROUTES.zrDocument,
      params: { zrDocumentId: item.id },
    });
  }

  clearAllFilters() {
    localStorage.removeItem('wochenabschluesseSearchData');
    localStorage.removeItem('wochenabschluesseSearchDataLastUpdate');

    this.actionUpdateWochenabschluesseSearchData(wochenabschluesseSearchData.defaultData());
    this.resetAllFacetOptionsAndSearch();
  }

  private async selectionUpdateOptions(newVal: any, action: any) {
    let expandedLists = document.querySelectorAll<HTMLElement>('.mdi-chevron-down.mdi-flip-v');
    expandedLists.forEach((element) => {
      element.click();
    });
    if (action == 'search') {
      this.searchFacetModeInWochenabschluesse(newVal);
    } else if (action == 'reset') {
      this.resetAllFacetOptionsAndSearch();
      this.getWochenabschluesseData();
    } else {
      if (this.isMounted && this.zrWochenabschluesseSearchParams !== undefined) {
        this.zrWochenabschluesseSearchParams.dataOption = newVal;
        this.getWochenabschluesseData();
      }
    }
  }

  private searchFacetModeInWochenabschluesse(e: any): void {
    const searchWord: string = e.filter;
    let selectedListItemId = document.querySelector('.v-list-item--highlighted .v-list-item__title')?.id;

    if (selectedListItemId != undefined) {
      let menuItem = this.menuItems.find((x: any) => x.field == selectedListItemId);
      this.facetSearch = false;
      this.searchInRealObjects(menuItem);
      return;
    }
    this.searchWord = searchWord;
    // if user press "enter" key again then make search in all fields
    if (this.facetSearch && this.menuItems.length != 0) {
      this.facetSearch = false;
      // return;
    }
    // handle facet search for words more than 2 letters
    if (searchWord?.length >= 2) {
      // const { start, end } = this.getStartEndPeriodForSearch();
      this.loadingFacet = true;
      this.makeFacetSearch(searchWord)
        .then((result: any) => {
          this.formatResultFromSearch(result);
          this.facetSearch = true;
          this.menuDisabled = false;
        })
        .finally(() => {
          this.loadingFacet = false;
        });
    } else if (searchWord?.trim().length === 0) {
      // load all invoices if press `Enter` with clear input
      this.getWochenabschluesseData();
    }
  }

  searchInRealObjects(item: any) {
    this.searchInRealWochenabschluesse(item);
  }

  searchInRealWochenabschluesse(item: any) {
    const searchParams = this.zrWochenabschluesseSearchParams; //this.searchParams;

    searchParams.andClauseFieldsIds.push({
      chipField: item.field,
      searchWord: this.searchWord,
    });
    if (item.field === 'all') {
      // searchParams.andClauseFieldsIds.push({
      //   chipField: item.field,
      //   searchWord: this.searchWord,
      // });
    }
    if (this.searchWord?.length >= 2 && item.title.length > 0) {
      // load all invoices if clear the input
      this.getWochenabschluesseSearchData.searchFacetFields = this.zrWochenabschluesseSearchParams.andClauseFieldsIds;
      const chipTextValue = `${item.fieldTranslated}: ${this.searchWord}`; // (GSP-209) move add chip logic before `then` to add it in `input` at once
      if (item.field != 'all') {
        this.chipData.push({
          chipText: chipTextValue,
          chipField: item.field,
          searchWord: this.searchWord,
        });
      }

      this.actiongetWochenabschluesseData({
        searchParams,
        wochenabschluesseSearchData: this.getWochenabschluesseSearchData,
      })
        .then((result: any) => {
          this.menuDisabled = true;
          if (item.field != 'all') {
            localStorage.setItem('wochenabschluesse-chipdata', this.chipDataString);
            this.updateWochenabschluesseSearchDataBasedOnLastUpdate();
          }
        })
        .catch((err:any) => {
          logger.error(err);
          this.removeChip(chipTextValue);
        })
        .finally(() => {
          this.resetFacetMenu();
        });
    }
  }

  get isHasSearchData() {
    return (
      this.getWochenabschluesse.total === 0 || this.wochenabschluesseIsLoading || this.isSearchDataHasNoValues()
    );
  }

  isSearchDataHasNoValues() {
    let values = Object.values(this.getWochenabschluesseSearchData);
    let result = values.some((x: any) => (x + '').length > 0);
    return !result;
  }

  get statuses() {
    return {
      1: { text: this.$t('status_chip.new'), class: 'none' },
      2: { text: this.$t('status_chip.book'), class: 'green' },
      3: { text: this.$t('status_chip.not_book'), class: 'red' },
      4: { text: this.$t('status_chip.rejected'), class: 'red' },
      5: { text: this.$t('status_chip.posted'), class: 'green' },
      6: { text: this.$t('status_chip.error'), class: 'orange' },
    };
  }

  get apiStatuses() {
    return [4, 5, 6];
  }

  //#region Logic related to `edit-status`  dialog menu
  currentItem = wochenabschluesse.parse({});
  showMenu = false;
  x = 0;
  y = 0;

  onShowMenu(e: any, item: any) {
    this.currentItem = item;

    let target = e.target;
    // if click on the `chip text`use parent as target (chip element)
    if (e.target.className.includes('v-chip__content')) {
      target = e.target.parentElement;
    }

    const rectCoordinates = target.getBoundingClientRect();
    e.preventDefault();
    this.showMenu = false;
    // this.x = e.clientX;
    // this.y = e.clientY;
    this.x = rectCoordinates.left;
    this.y = rectCoordinates.bottom + 5;

    this.$nextTick(() => {
      this.showMenu = true;
    });
  }
  closeMenu() {
    this.showMenu = false;
  }
  //#endregion

  //#region Facet
  private facetSearch = false;
  private menuDisabled = true;
  private searchWord = '';
  private menuItems: any = [];
  private chipData: Array<any> = [];

  get chipDataString() {
    return this.chipData.map((el: any) => `${el.chipField}:${el.searchWord}`).join(',');
  }

  removeChip(chipText: any) {
    const index = this.chipData.findIndex((x) => x.chipText === chipText);
    this.chipData.splice(index, 1);
    const indexOdata = this.zrWochenabschluesseSearchParams.andClauseFieldsIds.findIndex(
      (x: any) => x.chipText === chipText
    );
    this.zrWochenabschluesseSearchParams.andClauseFieldsIds.splice(index, 1);

    this.menuItems = [];
    this.menuDisabled = true;
    this.getWochenabschluesseDataWithParams();
  }

  private loadingFacet = false;
  /**
   * Make `facet search` in `zrDocuments` to know in which `zrDocuments` fields specified word was found
   * show `No results` if word was not found in any fields
   * @param {KeyboardEvent} e as KeyboardEvent (contains word from input)
   */

  async makeFacetSearch(searchWord: any) {
    let payload = {
      searchWord: searchWord,
      chipDataString: this.chipDataString,
    };
    // (GSP-102) always set correct payload for `ZrNummer` for GsUser to avoid show/search all records
    this.getWochenabschluesseSearchData.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    this.getWochenabschluesseSearchData.zrNummer = this.getZrNummerDependOnRole();
    payload = { ...this.getWochenabschluesseSearchData, ...payload };
    return this.actionGetWochenabschluesseFacetSearch(payload);
  }

  /**
   * Make the actual search in the `zrDocuments` table with returning `zrDocuments` based on results from `facet search`
   * @param {object} Menu item `object` with title(shown in menu) and field(backend field name) properties
   */

  formatResultFromSearch(result: any) {
    const fieldsWithResult = Object.keys(result).filter((key) => result[key] > 0);
    this.resetMenuItems();
    fieldsWithResult.forEach((element: string) => {
      const fieldName = element.charAt(0).toUpperCase() + element.slice(1);

      const fieldNameTranslated = this.$i18n.tc(`facet_search.${fieldName}`);
      this.menuItems.push({
        title: `${fieldName}: ${this.searchWord} (${result[element]})`,
        titleTranslated: `${fieldNameTranslated}: ${this.searchWord} (${result[element]})`,
        fieldTranslated: fieldNameTranslated,
        field: fieldName,
      });
    });
    if (fieldsWithResult.length === 0) {
      this.menuItems.push({
        titleTranslated: this.$i18n.tc('facet_search.no_results'),
      });
      return;
    }
    this.menuItems.unshift({
      titleTranslated: this.$i18n.tc('facet_search.all'),
      field: 'all',
      title: 'All',
    });
  }

  /**
    Should reset `menuItems` before add result from the next search otherwise the previous result will be summed with current result
    */
  private resetMenuItems() {
    this.menuItems = [];
  }
  private resetChips() {
    this.chipData = [];
  }

  resetFacetMenu() {
    this.menuDisabled = true;
    this.facetSearch = false;
    this.searchWord = '';
  }

  resetAllFacetOptionsAndSearch() {
    this.resetChips();
    this.resetMenuItems();
    this.resetFacetMenu();
    this.zrWochenabschluesseSearchParams.andClauseFieldsIds = [];
    this.zrWochenabschluesseSearchParams.filter = '';
  }

  get searchMenuObj() {
    return {
      facetSearch: this.facetSearch,
      menuDisabled: this.menuDisabled,
      menuItems: this.menuItems,
      hasResults: this.hasResults,
      chipData: this.chipData,
    };
  }

  // on prod for some reason no request for `zrDocuments` and `zrNummer` is empty (load all records but should load 0)
  setZrNummerInPayloadIfEmpty() {
    // set correct payload for `download PDF/excel` request
    this.getWochenabschluesseSearchData.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    this.getWochenabschluesseSearchData.zrNummer = this.getZrNummerDependOnRole();
  }

  get hasResults() {
    return this.menuItems.length > 0 && !!this.menuItems[0]?.field;
  }
  //#endregion

  getCurrencySymbol(currency: any) {
    // (GSP-212) move `toLowerCase` logic here to solve `currency` NULL error from HTML
    if (currency) {
      currency = currency.toLowerCase();
    }
    let currencySymbol: string = '';
    if (currency == 'euro' || currency == 'eur' || currency == '' || !currency) {
      currencySymbol = ' €';
    } else if (currency == 'chf') {
      currencySymbol = ' CHF';
    } else if (currency == 'usd') {
      currencySymbol = ' $';
    }

    return currencySymbol;
  }

  async downloadFile(file: any) {
    if (file.file_id == null || !file.file_id?.startsWith('P')) {
      return;
    }

    const payload = {
      documentType: 'Belege',
      recordId: file.file_id,
      dmsNr: '',
    };

    await this.actionGetAzureFile(payload)
      .then((result: any) => {
        let nameFile: string = file.file_name;
        if (file.fileTitle == 'Sammel-PDF' && !file.file_name.endsWith('.pdf')) {
          nameFile = file.file_name + '.pdf';
        }
        let fileName = nameFile;
        const type = DownloadUtils.getContentTypeFromExtension(fileName, file.mimetype);

        // (GSP-211) if no extension then add `type` to end of `fileName`
        if (!!type && type.length <=4 && !fileName.endsWith(`.${type}`)) {
          fileName += `.${type}`;
        }

        const blob = new Blob([result], { type: type });
        const url = DownloadUtils.createDownloadLink(blob, fileName);
        window.URL.revokeObjectURL(url);
        return fileName;
      })
      .catch((err: any) => {
        logger.error(err);
      });

    let documentId = file.documentId ? file.documentId : file.id;

    let payloadLog = {
      documentId: documentId, //file.filePath,
      processType: 'file_download',
    };

    await this.actionCreateWochenabschluesseLogRecords(payloadLog);
  }

  private expandTable = {
    src: 'expand_table.svg',
    header: '',
    height: 28,
    width: 28,
  };

  private table = {
    src: 'table.svg',
    header: '',
    height: 28,
    width: 28,
  };

  private selectionViewTable(newVal: any) {
    this.tableView = newVal;
    this.getWochenabschluesseData();
  }

  private fileCSV = {
    src: 'csv.svg',
    header: '',
    height: 32,
    width: 32,
  };

  private filePDF = {
    src: 'pdf.svg',
    header: '',
    height: 32,
    width: 32,
  };

  private fileExcel = {
    src: 'excel.svg',
    header: '',
    height: 32,
    width: 32,
  };

  private fileZIP = {
    src: 'zip.svg',
    header: '',
    height: 32,
    width: 32,
  };

  private existExtention(exstention: any) {
    if (exstention != null && exstention.length != 0) {
      if (exstention == 'application/pdf' || exstention == 'text/csv' || exstention == 'application/zip') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  private bereitsstellenIcon = {
    src: 'bereitsstellen.svg',
    header: '',
    height: 32,
    width: 32,
  };

  private bereitsstellenIconDisabled = {
    src: 'bereitsstellen_grey.svg',
    header: '',
    height: 32,
    width: 32,
  };

  titleFile(fileTitle: any) {
    return (
      fileTitle == 'PDF-Belege (ZIP für WaWi)' ||
      fileTitle == 'Auflistung (WaWi, dias.exp)' ||
      fileTitle == 'Avis (WaWi)' ||
      fileTitle == 'PDF-Belege (ZIP zur Ansicht)'
    );
  }

  sendRabbitMqMess(file: any) {
    let documentId = file.documentId ? file.documentId : file.id;
    const payload = {
      id: documentId,
      receiverNumber: file.zr_number,
      endOfWeekDate: file.end_of_week_date,
      documentId: file.file_id,
      processType: 'sftp_request',
    };
    this.actionSendWochenabschluesseRabbitMqMess(payload)
      .then((data: any) => {
        file.process_type = data.result.process_type;
        file.username = data.result.username;
        file.date_event = data.result.date_event;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  getDisabled(fileId: any, dateRequest: any) {
    return false;
    // ds, 28.11.2024 -> functionality is temporarily not used as agreed with the customer
    // let dateResponse = this.listlogEndWeek.find((x: any) => x.documentId == fileId)?.date_event;
    // if (dateResponse != undefined) {
    //   let responseDate = new Date(dateResponse).getTime();
    //   let requestDate = new Date(dateRequest).getTime();
    //   if (responseDate > requestDate) {
    //     return false;
    //   }
    // } else if (dateRequest  == null  || dateRequest.length == 0) {
    //   return false;
    // }
    // return true;
  }

  private onMouseOver(documentId: any) {
    this.actionHistoryFileOfEndWeek(documentId)
      .then((result: any) => {
        this.historyFileOfEndWeekList = result;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }
}
